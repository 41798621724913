<template>
  <Modal
    v-model="downloadModal"
    width="400"
  >
    <p
      slot="header"
      class="text-center"
    >
      <span>选择工单作业日期范围</span>
    </p>
    <div class="m-b-10">
      <Select
        v-model="downloadType"
        size="small"
        :clearable="true"
        placeholder="选择任务类型"
      >
        <Option
          v-for="item in taskitemTypeArray"
          :key="'type_'+item.value"
          :value="item.value"
        >{{item.name}}</Option>
      </Select>
    </div>
    <div class="text-center">
      <DatePicker
        placeholder="作业日期"
        size="small"
        v-model='downloadActionDate'
        @on-change="changeActionDate"
        type="daterange"
        style="width:100%"
      ></DatePicker>
    </div>
    <div slot="footer">
      <i-button
        type="primary"
        size="small"
        :disabled="disabled"
        @click="onConfirmDownload"
      >确认</i-button>
    </div>
  </Modal>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    taskitemTypeArray: [Array]
  },
  data () {
    return {
      downloadModal: false,
      downloadType: undefined,
      downloadActionDate: []
    }
  },
  computed: {
    disabled () {
      if (this.downloadActionDate.length && this.downloadActionDate[0]) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    showModal () {
      this.downloadType = undefined
      this.downloadActionDate = []
      this.downloadModal = true
    },
    changeActionDate (date) {
      const that = this
      var oneTime = new Date().setTime(new Date(date[0]).getTime())
      var twoTime = new Date().setTime(new Date(date[1]).getTime())
      if (oneTime + 3600 * 1000 * 24 * 93 < twoTime) { // 判断开始时间+30天是否小于结束时间
        that.downloadActionDate = []
        this.$Notice.info({ desc: '作业日期选择范围最大为三个月' })
      } else {
        that.downloadActionDate = date
      }
    },
    onConfirmDownload () {
      if (!this.downloadActionDate || this.downloadActionDate.length === 0 || this.downloadActionDate[0] === '') {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }
      const downloadParams = {
        startDate: this.downloadActionDate[0],
        endDate: this.downloadActionDate[1],
        type: this.downloadType
      }
      const filename = '工单明细(' + downloadParams.startDate + '至' + downloadParams.endDate + ').xlsx'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/downloadplanexcel', downloadParams, filename)
      this.actionDateModal = false
    }
  }
}
</script>
